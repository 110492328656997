import React from "react"
import qstData from "./data/qst.json"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import Styles from "./css/qst.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Carbonate_disc_search from "src/components/carbonate_disc_search"

class Nonbmaqst extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: qstData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
        window.mapParams();
    }

    });
  }

  render() {
    return (
      <div className = {Styles.qst} style={{ display: this.state.display }}>
        <header>
      <img src={this.state.data.topLogo} />
      </header>
       <main>
      <h1 className="hc_param">{this.state.data.headline}</h1>
      <h2>{this.state.data.subhead}</h2>
      <ul className = {Styles.bullets}>
              {this.state.data.bullets != undefined ? this.state.data.bullets.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
      </ul>
      <button data-cy="cta" onClick = {() => window.triggerInstall()}
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }} className="cl1"></button>
                <Carbonate_disc_search></Carbonate_disc_search>
      <footer>
        
      </footer>
       </main>
            </div>






    )
  }
}

export default Nonbmaqst
