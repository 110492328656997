import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import QstLayout from "src/components/qstLayout"
import Carbonate_disc_search from "src/components/carbonate_disc_search"
import Btf_carbonate from "src/components/btf_carbonate"


const qstlpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
     main #carbonate_disc_search-module--carbonate_disc_search .carbonate_disc_search-module--carbDisclosure {
      font-weight:300;
      color:#E0C1FB;
     }

     main #carbonate_disc_search-module--carbonate_disc_search .carbonate_disc_search-module--carbDisclosure a {
      font-weight:700;
      color:#E0C1FB;
     }

  



    `}
    </style>
    <title>Fast, Easy and Safe Web Browsing - Carbonate</title></Helmet>
    <section>
      <QstLayout data={qstlpData}><Carbonate_disc_search></Carbonate_disc_search></QstLayout>
      </section>
      <Btf_carbonate data={btfData}></Btf_carbonate>
    </HomepageLayout>
  )
}
